<template>
  <footer class="footer">
    <a href="#go-up" class="go-to-up"><img src="@/assets/images/icon-up.svg" alt="go-up"></a>
    <div class="footer-content">
      <section class="agent-buttons">
        <div class="img-container">
            <img src="@/assets/images/Logo.png" alt="Triplecrown Sports" width="auto" height="auto">
        </div>
            <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">agents classic</a>
            <a class="primary-button primary-button__new" :href="agentNew" target="_blank">agents new</a>
      </section>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
    </div>
  </footer>
  <!-- <div class="footer-copyright">
    Wager Your Mojo © 2023 TERMS AND CONDITIONS
  </div> -->
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {
      
      const agentClassic = "https://agents.triplecrown-sports.com/";
      const agentNew = "https://adm.triplecrown-sports.com/";
      return {
        agentClassic,
        agentNew,
        routes: [
        {
            name       :  'sportsbook',
            route      :  'sportsbook'
          },
          {
            name       :  'live betting',
            route      :  'live-betting'
          },
          {
            name       :  'racebook',
            route      :  'racebook'
          },
          {
            name       :  'casino',
            route      :  'casino'
          },
          {
            name       :  'house rules',
            route      :  'house-rules'
          },
          {
            name       :  'sports rules',
            route      :  'sports-rules'
          },
          {
            route      :  'same-game-parlay-rules',
            name        : 'same game parlay rules'
          }
        ]
      }
    },
  };
</script>
